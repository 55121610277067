import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Hero from "../../components/Hero"

const query = graphql`
{
  psicoterapiaIndividuale: file(base: {eq: "psicoterapia-individuale.jpg"}, sourceInstanceName: {eq: "assets"}) {
    childImageSharp {
      gatsbyImageData(
        layout: FULL_WIDTH,
        aspectRatio: 1,
      )
    }
  },
  sostegnoPsicologico: file(base: {eq: "sostegno-psicologico.jpg"}, sourceInstanceName: {eq: "assets"}) {
    childImageSharp {
      gatsbyImageData(
        layout: FULL_WIDTH,
        aspectRatio: 1,
      )
    }
  },
  consulenzaPsicologica: file(base: {eq: "consulenza-psicologica.jpg"}, sourceInstanceName: {eq: "assets"}) {
    childImageSharp {
      gatsbyImageData(
        layout: FULL_WIDTH,
        aspectRatio: 1,
      )
    }
  },
  terapiaDiCoppia: file(base: {eq: "terapia-di-coppia.jpg"}, sourceInstanceName: {eq: "assets"}) {
    childImageSharp {
      gatsbyImageData(
        layout: FULL_WIDTH,
        aspectRatio: 1,
      )
    }
  },
  valutazionePsicodiagnostica: file(base: {eq: "valutazione-psicodiagnostica.jpg"}, sourceInstanceName: {eq: "assets"}) {
    childImageSharp {
      gatsbyImageData(
        layout: FULL_WIDTH,
        aspectRatio: 1,
      )
    }
  },
}
`

const PrestazioniPage = () => {
  const {
    psicoterapiaIndividuale,
    sostegnoPsicologico,
    consulenzaPsicologica,
    terapiaDiCoppia,
    valutazionePsicodiagnostica,
  } = useStaticQuery(query);

  return <Layout>
    <Seo
      title="Prestazioni"
      description="Cosa propongo: Psicoterapia Individuale, Sostegno Psicologico, Consulenza Psicologica, Valutazione Psicodiagnostica. Tali prestazioni sono pensate per soggetti adulti o adolescenti, in forma vis-à-vis. È comunque prevista anche la possibilità di svolgere sedute online tramite Skype."
    />
    <Hero title="Prestazioni" />
    <section className="grid grid-cols-6 py-6">
      <article className="col-span-6 md:col-start-2 md:col-span-4 px-5 md:p-0">
        <h1>Cosa propongo:</h1>
        <ul className="list-disc list-outside pl-9 mt-3">
          <li>Psicoterapia Individuale</li>
          <li>Sostegno Psicologico</li>
          <li>Consulenza Psicologica</li>
          <li>Terapia di coppia</li>
          <li>Valutazione Psicodiagnostica</li>
        </ul>
        <p className="mt-2">Tali prestazioni sono pensate per soggetti <b>adulti</b> o <b>adolescenti</b>, in forma <b>vis-à-vis</b>. È comunque prevista anche la possibilità di svolgere sedute online tramite <b>Skype</b>.</p>
      </article>
    </section>
    <section className="grid grid-cols-12 py-6">
      <article className="col-span-12 md:col-start-3 md:col-span-4 xl:col-start-3 xl:col-span-2 px-5 pb-5 md:px-0">
        <div className="md:px-3">
          <GatsbyImage
            image={psicoterapiaIndividuale.childImageSharp.gatsbyImageData}
            alt="Cover per Psicoterapia Individuale"
          />
          <h2 className="text-2xl text-center py-3">Psicoterapia Individuale</h2>
          <p className="text-justify">Si tratta di un processo di <b>cambiamento globale</b>, che riguarda la <b>struttura della personalità</b> coinvolgendo non solo gli aspetti "portatori di sofferenza" (<i>egodistonici</i>) ma anche quelli apparentemente funzionali ed accettati (<i>egosintonici</i>).</p>
          <br />
          <p className="text-justify">E' quindi un percorso <b>che va in profondità</b>, che <b>richiede tempo</b> e la disponibiltà a <b>mettersi in discussione</b>.</p>
        </div>
      </article>
      <hr className="col-span-12 md:hidden mb-8 mx-5 text-grey h-2" />
      <article className="col-span-12 md:col-span-4 xl:col-start-6 xl:col-span-2 px-5 pb-5 md:px-0">
        <div className="md:px-3">
          <GatsbyImage
            image={sostegnoPsicologico.childImageSharp.gatsbyImageData}
            alt="Cover per Sostegno Psicologico"
          />
          <h2 className="text-2xl text-center py-3">Sostegno Psicologico</h2>
          <p className="text-justify">E' rivolto al miglioramento del <b>benessere globale</b> della persona che viene aiutata a fronteggiare una situazione problematica, presente nel <i>qui ed ora</i> e in qualche modo inevitabile (es. passaggi esistenziali, momenti di crisi, eventi più o meno penosi o catastrofici, una malattia propria o di persone affettivamente vicine).</p>
          <br />
          <p className="text-justify">Si basa essenzialmente sull'ascolto ed il supporto emotivo ed è finalizzato ad <b>accompagnare la persona, accoglierne i bisogni emergenti, mobilitare risorse</b>, fornire suggerimenti utili alla <b>gestione</b> della vita quotidiana.</p>
        </div>
      </article>
      <hr className="col-span-12 md:col-start-3 md:col-span-8 xl:hidden mb-8 mx-5 md:mx-0 text-grey h-2" />
      <article className="col-span-12 md:col-start-5 md:col-span-4 xl:col-start-9 xl:col-span-2 px-5 pb-5 md:px-0">
        <div className="md:px-3">
          <GatsbyImage
            image={consulenzaPsicologica.childImageSharp.gatsbyImageData}
            alt="Cover per Consulenza Psicologica"
          />
          <h2 className="text-2xl text-center py-3">Consulenza Psicologica</h2>
          <p className="text-justify">Rispetto ad un intervento profondo e strutturale come nel caso della psicoterapia, in questo caso il focus dell'intervento riguarda un <b>problema ben definibile e circoscritto</b> che non invalida il senso della propria identità personale: si tratta quindi, spesso, di una difficoltà nell'effettuare delle scelte, nel prendere decisioni rispetto ad una situazione di <i>impasse</i> momentanea.</p>
          <br />
          <p className="text-justify">Si caratterizza quindi per un <b>intervento di breve durata</b> in cui lo psicologo, focalizzandosi insieme al paziente sullo specifico problema, lo aiuta a vagliare tutte le possibili soluzioni facilitando il processo decisionale e l'utilizzo delle risorse presenti.</p>
        </div>
      </article>
      <hr className="col-span-12 md:col-start-3 md:col-span-8 mb-8 mx-5 text-grey h-2" />
      <article className="col-span-12 md:col-start-3 md:col-span-4 xl:col-start-4 xl:col-span-2 px-5 pb-5 md:px-0">
        <div className="md:px-3">
          <GatsbyImage
            image={terapiaDiCoppia.childImageSharp.gatsbyImageData}
            alt="Cover per Terapia di coppia"
          />
          <h2 className="text-2xl text-center py-3">Terapia di coppia</h2>
          <p className="text-justify">In questo caso è la coppia, il <b>legame</b> esistente tra due persone, ad essere il soggetto dell’intervento terapeutico. Una coppia può entrare in crisi per innumerevoli ragioni (calo del desiderio, tradimenti, difficoltà nella gestione dei figli…) e può essere utile uno spazio per contenere, pensare la crisi e trovare la propria personale strada per affrontarla. In generale, l’intervento si articola in due fasi: una prima di <b>consultazione</b>, con una tempistica circoscritta, utile a “mettere a fuoco” la natura del problema e a volte di per sé sufficiente a sbloccare la situazione; una seconda, di <b>psicoterapia</b> vera e propria, più lunga e strutturata qualora la consultazione non sia percepita come sufficiente.</p>
        </div>
      </article>
      <hr className="col-span-12 md:hidden mb-8 mx-5 text-grey h-2" />
      <article className="col-span-12 md:col-span-4 xl:col-start-8 xl:col-span-2 px-5 pb-5 md:px-0">
        <div className="md:px-3">
          <GatsbyImage
            image={valutazionePsicodiagnostica.childImageSharp.gatsbyImageData}
            alt="Cover per Valutazione Psicodiagnostica"
          />
          <h2 className="text-2xl text-center py-3">Valutazione Psicodiagnostica</h2>
          <p className="text-justify">Si tratta di un <b>approfondimento del funzionamento psicologico</b> finalizzato non tanto ad uno sterile etichettamento diagnostico quanto piuttosto ad aiutare il paziente a cogliere alcuni aspetti poco conosciuti di sè, non solo in termini di fragilità ma anche di risorse.</p>
          <br />
          <p className="text-justify">La <b>diagnosi</b> non dovrebbe mai essere un punto di arrivo ma piuttosto un punto di partenza utile per valutare il percorso migliore da intraprendere, consapevoli del fatto che <b>la complessità dell'individuo non potrà mai essere racchiusa dentro una parola o una sigla</b>.</p>
        </div>
      </article>
    </section>
    <section className="grid grid-cols-6 py-6">
      <article className="col-span-6 md:col-start-2 md:col-span-4 px-5 md:p-0 mb-12">
        <h1 className="mb-3">Come funziona</h1>
        <p>Dopo un <b>primo contatto</b> via messaggio o per telefono, verrà fissato un <b>incontro conoscitivo</b> nel mio studio in cui si potrà riflettere insieme sulle difficoltà che hanno portato a cercare un aiuto, sul tipo di richiesta che viene avanzata, sulle aspettative ed i desideri legati al percorso che si vuole intraprendere. Da qui prenderà il via una fase consultiva (che in genere si articola in 3 incontri) che permetterà di delineare un quadro abbastanza chiaro e stabilire quindi il percorso migliore da seguire. Al termine della fase consultiva viene anche proposto un <b>contratto terapeutico</b>, relativo alla privacy e agli obblighi reciproci di terapeuta e paziente.</p>
        <br/>
        <p>La durata di un <b>colloquio</b>, di <b>consultazione</b> o <b>clinico</b>, è generalmente di <b>50 minuti</b>. Una <b>valutazione psicodiagnostica</b> ha invece una durata variabile.</p>
        <br/>
        <p>La <b>durata dei percorsi</b> è strettamente legata alle <b>caratteristiche del soggetto</b> e al <b>clima</b> che si instaura con il terapeuta: una consulenza psicologica può concludersi in pochi incontri, un sostegno psicologico in 3-6-9 mesi, una psicoterapia individuale può articolarsi lungo 1-2 anni. Si tratta però di previsioni di massima, con mero valore statistico, che non considerano quindi la specificità di ogni soggetto. Ognuno, in verità, ha un suo specifico tempo. Questo però non significa che i sintomi più marcati e fonte di sofferenza non possano essere ridotti in tempi ragionevolmente brevi.</p>
        <br/>
        <p>La <b>frequenza</b> varia in funzione del tipo di lavoro che si vuole svolgere ma, in generale, prevede <b>almeno un colloquio alla settimana</b>.</p>
      </article>
      <article className="col-span-6 md:col-start-2 md:col-span-4 px-5 md:p-0 mb-12">
        <h1 className="mb-3">Privacy</h1>
        <p>Il <b>Codice Deontologico degli Psicologi Italiani</b> stabilisce che il professionista è "<i>strettamente tenuto al segreto professionale</i>" (art. 11) e nulla può essere rivelato a terzi senza il consenso del paziente, salvo "<i>qualora si prospettino gravi pericoli per la vita o per la salute psicofisica del soggetto e/o di terzi</i>" (art. 13). Lo studio dello psicologo è quindi un posto <b>sicuro</b>, <b>intimo</b> e <b>segreto</b>. Gli incontri vengono fissati in modo tale da limitare al minimo la possibilità di incontrarsi con altri pazienti.</p>
        <br/>
        <p>Per un approfondimento della <b>Privacy Policy</b>, <Link to="/privacy-policy">clicca qui</Link>.</p>
      </article>
      <article className="col-span-6 md:col-start-2 md:col-span-4 px-5 md:p-0 mb-12">
        <h1 className="mb-3">Costi</h1>
        <p>Con l’entrata in vigore della <a href="https://www.bosettiegatti.eu/info/norme/statali/2012_0027.htm#09"><b>legge n. 27/2012</b></a>, le professioni regolamentate da sistema ordinistico (come quella dello psicologo) non possiedono più un tariffario. A titolo puramente indicativo, l'Ordine degli Psicologi ha mantenuto comunque disponibile per i cittadini ed i professionisti la vecchia tabella tariffaria (<a href="https://www.opl.it/area-normativa/Nomenclatore.php?t=1434"><b>visionabile qui</b></a>).</p>
        <br/>
        <p>In linea con quanto stabilito dal <a href="https://www.psy.it/codice-deontologico-degli-psicologi-italiani"><b>Codice Deontologico degli Psicologi Italiani</b></a> (Art. 23), il costo viene pattuito nella fase iniziale del rapporto in funzione del tipo di lavoro che si intende svolgere, modulandolo insieme al cliente tenendo conto della sua specifica situazione, tutelando al contempo la dignità della professione. Sono previste <b>tariffe agevolate</b> per <i>studenti universitari</i> e casi con <i>situazioni socio-economiche particolari</i>.</p>
        <br/>
        <p>Il pagamento avviene in genere mensilmente e può essere effettuato tramite <b>Bonifico</b> o <b>POS</b>.</p>
        <br/>
        <p>Si segnala inoltre che trattandosi di <b>prestazioni sanitarie</b> queste sono detraibili ai fini <b>IRPEF</b> dal proprio <b>730</b> per un valore del <b>19%</b> totale della spese sostenuta a lordo di una franchigia pari a 129,11 euro.</p>
      </article>
    </section>
  </Layout>
}

export default PrestazioniPage
